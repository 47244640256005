'use client';
import { PFText } from './PFText';
import { Suspense } from 'react';
import { PFButton } from './PFButton';
import { usePush } from '@/common/usePush';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';
import PFLink from './PFLink';

export const pushWidgetClosedAtom = atomWithStorage<boolean>(
  'pushWidgetClosed',
  false,
  undefined,
  {
    getOnInit: true,
  },
);

export function PushNotificationsWidget() {
  const { isPushAviable, askPermission, subscribeButtonOnClick, isSubscribed } =
    usePush(undefined, true);

  return (
    <Suspense>
      <article className="w-full sm:w-[300px] bg-secundaryColor flex flex-col gap-m p-base text-center rounded-xl justify-center">
        {!isSubscribed && (
          <>
            <PFText tag="h2" className="text-white font-semibold" size="base">
              Verpasse keine Promiflash-Topnews!
            </PFText>
            <PFText className="text-white" size="small">
              Melde dich jetzt für Push-Benachrichtigungen an und bleib immer
              auf dem Laufenden.
            </PFText>
            <PFButton
              onClick={(event) => {
                askPermission()
                  .then(() => {
                    subscribeButtonOnClick(event);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
              variant={'invertedHover'}
            >
              <PFText size="small" className="font-normal">
                Push Aktivieren
              </PFText>
            </PFButton>
            {/* <button onClick={() => setPushWidgetClosed(true)}>
              <PFText size="small" className="font-normal text-white p-3 mt-1">
                Nein Danke
              </PFText>
            </button> */}
          </>
        )}
        {isSubscribed && (
          <>
            <PFText tag="h2" className="text-white font-semibold" size="base">
              Verwalte deine Push Einstellungen
            </PFText>
            <PFText className="text-white" size="small">
              Bleibe auf dem Laufenden über die aktuellsten Nachrichten aus der
              Welt der Stars.
            </PFText>
            <PFLink href={'/push'}>
              <PFButton asChild variant="invertedHover">
                <PFText size="small" className="font-normal">
                  Push Einstellungen
                </PFText>
              </PFButton>
            </PFLink>
          </>
        )}
        {!isPushAviable && (
          <>
            <PFText tag="h2" className="text-white font-semibold" size="base">
              Push Nachrichten
            </PFText>
            <PFText className="text-white" size="small">
              Dein Browser scheint schon ein paar Jahre älter zu sein.
              Aktuallisiere ihn, um Push Nachrichten aktivieren zu können.
            </PFText>
          </>
        )}
      </article>
    </Suspense>
  );
}
